<template>
  <v-container>
    <counselor-client-profile v-if="clientProfile!=null" :client="clientProfile" :cycles="clientCycles"></counselor-client-profile>
  </v-container>
</template>

<script>
import CounselorClientProfile from "./CounselorClientProfile";
// import {mapGetters} from 'vuex'
import {EventBus} from "@/event-bus";

export default {
  data() {
    return {
      clientProfile: null,
      clientCycles: []
    }
  },
  components: {
    CounselorClientProfile
  },
  computed: {
    // ...mapGetters({
    //     clientStartSession: 'getClientStartSession'
    // })
  },
  methods: {
    getClient() {
      EventBus.$emit('setLoading', true);
      this.$store.dispatch('getClientInfo', this.$route.params.clientId).then((resp) => {
        this.clientProfile = resp.data.client;
        this.clientCycles = resp.data.cycles;
      }).finally(() => {
        EventBus.$emit('setLoading', false);
      });
      // this.$store.dispatch('getClientStartSession', this.$route.params.clientId).then((data) => {
      //     this.clientProfile = data.data;
      // })
    },
  },
  created() {
    this.getClient();
  }
}
</script>

<style>

</style>